import React from 'react'
import Layout from '../components/layout'
import SimpleNav from '../components/simple-nav'
import navigation from '../navigation'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <SimpleNav navigation={navigation} />

      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div className="relative h-full text-lg max-w-prose mx-auto" aria-hidden="true">
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="max-w-prose mx-auto">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-800 sm:text-4xl">
                Privacy Policy
              </span>
            </h1>
            <p className="mt-8 text-lg text-gray-600 leading-8">
              MGPP Ltd understands that your privacy is important to you and that you care about how your personal data is used.
              We respect and value the privacy of everyone who visits our website and/or any systems built by us and will only collect and use personal data in ways that are described here,
              within reasonable means pertaining to the system/service you are using and in a way that is consistent with our obligations and your rights under the law.
            </p>
          </div>
          <div className="mt-6 prose prose-teal text-gray-600 mx-auto">
            <p>
              This Privacy Policy describes the practices of MGPP Ltd (<strong>"we"</strong>, <strong>"us"</strong> or <strong>"our"</strong>) and applies only to your use of the <strong>"Site"</strong>, which may contain links to other websites.
              Please note that we have no control over how your data is collected, stored, or used by other websites and we advise you to check the privacy policies of any such websites before providing any data to them.
            </p>
            <h3>
              Definitions and Interpretation
            </h3>
            <p>
              In this Policy the following terms shall have the following meanings:
            </p>
            <ul role="list">
              <li>
                <strong>"Site"</strong> means any website and/or system built by MGPP Ltd and hosted for or managed on behalf of our customers or any subsidiaries thereof.
              </li>
            </ul>
            <h2>
              Index
            </h2>
            <p>
              <AnchorLink to={'/privacy-policy#personal-information-we-collect'} title="Personal information we collect" />
            </p>
            <p>
              <AnchorLink to={'/privacy-policy#how-we-use-your-personal-information'} title="How we use your personal information" />
            </p>
            <p>
              <AnchorLink to={'/privacy-policy#cookies'} title="Cookies" />
            </p>
            <p>
              <AnchorLink to={'/privacy-policy#how-to-contact-us'} title="How to contact us" />
            </p>
            <p>
              <AnchorLink to={'/privacy-policy#changes-to-this-privacy-policy'} title="Changes to this Privacy Policy" />
            </p>
            <h2 id="personal-information-we-collect">
              Personal information we collect
            </h2>
            <h3>
              Information you provide us
            </h3>
            <p>
              Personal information you may provide us through the use of the Site
            </p>
            <ul role="list">
              <li>
                <strong>Contact details,</strong> such as your forename, surname, email address, phone number and company name.
              </li>
              <li>
                <strong>Communications</strong> that we exchange with you, including when you contact us.
              </li>
            </ul>
            <h3>
              Automatic data collection
            </h3>
            <p>
              We and our service providers may automatically log information about you, your computer or mobile device and your interaction over time with the Site
            </p>
            <ul role="list">
              <li>
                <strong>Device data,</strong> such as your computer’s or mobile device’s operating system type and version, manufacturer and model, browser type, screen resolution, RAM and disk size, CPU usage, device type (e.g., phone, tablet), IP address, unique identifiers (including identifiers used for advertising purposes), language settings and general location information such as geographic area.
              </li>
              <li>
                <strong>Online activity data,</strong> such as pages or screens you viewed, how long you spent on a page or screen, the website you visited before browsing to the Site, navigation paths between pages or screens, information about your activity on a page or screen, access times, and duration of access.
              </li>
              <li>
                <strong>Cookies,</strong> which are text files that websites store on a visitor’s device to uniquely identify the visitor’s browser or to store information or settings in the browser for the purpose of helping you navigate between pages efficiently, remembering your preferences, enabling functionality, helping us understand user activity and patterns, and facilitating online advertising.
              </li>
            </ul>
            <h2 id="how-we-use-your-personal-information">
              How we use your personal information
            </h2>
            <p>
              We use your personal information for the following purposes or as otherwise described at the time of collection:
            </p>
            <h3>
              Service delivery
            </h3>
            <ul role="list">
              <li>
                Communicating, with you about the Site and/or Services where we have received a prior communication from you.
              </li>
              <li>
                Provide, operate and improve the Site and our business.
              </li>
            </ul>
            <h2 id="cookies">
              Cookies
            </h2>
            <p>
              We use Google reCAPTCHA and Google Analytics to help us understand user activity on the Service.
              You can learn more about Google Analytics cookies at <a href="https://developers.google.com/analytics/resources/concepts/gaConceptsCookies" target="_blank" rel="noopener noreferrer">developers.google.com/analytics/resources/concepts/gaConceptsCookies</a> and about how Google protects your data at <a href="https://www.google.com/analytics/learn/privacy.html" target="_blank" rel="noopener noreferrer">www.google.com/analytics/learn/privacy.html</a>.
            </p>
            <p>
              You can prevent the use of Google Analytics relating to your use of our sites by downloading and installing a browser plugin available at <a href="https://tools.google.com/dlpage/gaoptout?hl=en" target="_blank" rel="noopener noreferrer">tools.google.com/dlpage/gaoptout?hl=en</a>.
            </p>
            <h2 id="how-to-contact-us">
              How to contact us
            </h2>
            <p>
              Should you wish to contact us regarding your personal information or data protection, you can reach us by email at <a href="mailto:data@mgpp.dev">data@mgpp.dev</a>.
            </p>
            <h2 id="changes-to-this-privacy-policy">
              Changes to this Privacy Policy
            </h2>
            <p>
              We may change this Privacy Notice from time to time.
              This may be necessary, for example, if the law changes, or if we change our business in a way that affects personal data protection.
              Any changes will be immediately posted on the Site and you will be deemed to have accepted the terms of the Privacy Policy on your first use of the Site following the alterations.
              We recommend that you check this page regularly to keep up-to-date.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PrivacyPolicyPage