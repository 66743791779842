import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import classNames from '../classNames'

// const navigation = [
//   { name: 'Home', href: '/' },
//   // { name: 'About', href: '#about' },
//   { name: 'Contact Us', href: '/contact-us' },
// ]

export default function SimpleNav({ backgroundColour, textColour, navigation }) {
  return (
    <header className={classNames("relative", backgroundColour || 'bg-white')}>
      {/* <div className="absolute inset-0 shadow z-30 pointer-events-none" aria-hidden="true" /> */}
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-between border-b border-teal-500 lg:border-none">
          <div className="flex items-center">
            <a href="/">
              <span className="sr-only">Workflow</span>
              {/* <img
                className="h-10 w-auto"
                src="https://tailwindui.com/img/logos/workflow-mark.svg"
                alt=""
              /> */}
              <StaticImage
                src="../images/logo.svg"
                loading="eager"
                placeholder="tracedSVG"
                height={40}
              />
            </a>
            <div className="hidden ml-10 space-x-8 lg:block">
              {navigation.map((link) => (
                <a key={link.name} href={link.href} className={classNames("text-base font-medium hover:text-gray-800", textColour || 'text-gray-500')}>
                  {link.name}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="py-4 flex flex-wrap justify-center space-x-6 lg:hidden">
          {navigation.map((link) => (
            <a key={link.name} href={link.href} className={classNames("text-base font-medium hover:text-gray-800", textColour || 'text-gray-500')}>
              {link.name}
            </a>
          ))}
        </div>
      </nav>
    </header>
  )
}
